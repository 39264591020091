import { Stack } from '@mui/material';
import {
    GridEditSingleSelectCell,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowParams,
    GridValueOptionsParams,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid-premium';
import { alphaNumericSort } from '@price-for-profit/micro-services';
import { RemoveableGridColDef } from 'pages/productPrices/productPricesResolver';
import { useMemo } from 'react';
import {
    DynamicEditableRenderCell,
    LONG_DATE_COLUMN,
    PERCENT_COLUMN,
    SHORT_DATE_COLUMN,
} from 'shared/components/app/DataGridCells/columnDefs';

import { ProvideAnalyticsPrerequisites, ProvideAvailableAnalyticsReports } from 'packages/individualAnalytics';

import { modifiedByFormatter, useIsDemoMode, useTitle, useUser } from '@insight2profit/drive-app';
import { NullCell, ValidatedRenderEditCell } from 'shared/components/app/DataGridCells';
import { DocumentCurrencyCell } from 'shared/components/app/DataGridCells/DocumentCurrencyCell';
import { UnitOfMeasureCell } from 'shared/components/app/DataGridCells/UnitOfMeasureCell';
import {
    CustomerPricesCurrentSAPPriceStaticRenderCell,
    CustomerPricesExceptionsActions,
    CustomerProductApprovalActions,
} from 'shared/components/app/customerPrices';
import { CustomerPricesSubmitAction } from 'shared/components/app/customerPrices/CustomerPricesSubmitAction';
import { PageLayout } from 'shared/components/layouts';
import Loading from 'shared/components/routing/loading';
import { useUserPermissions } from 'shared/hooks';
import { useAnalyticsAppInfo } from 'shared/hooks/use-analytics-app-info';
import { useExchangeRates } from 'shared/providers';
import { ProvideSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { IViewCustomerPrices } from 'shared/types';
import {
    PositiveNonZero,
    ValidFromPreProcessor,
    ValidToPreProcessor,
    compareAscendingScaleValues,
    compareDescendingAmountValues,
    customerPricesForeignCurrencyValueGetterCurried,
    customerPricesForeignCurrencyValueSetterCurried,
    formatNumberNoDecimal,
    perQuantityValidationCheck,
} from 'shared/utility';
// import { CustomerPriceEditPriceType } from './CustomerPriceEditPriceType';
import { isNil } from 'lodash';
import { CustomerPricesForeignCurrencyRenderCell } from 'shared/components/app/customerPrices/CustomerPricesForeignCurrencyRenderCell';
import { EXCEPTION_STATUS_OPTIONS } from 'shared/constants';
import { useUomDropdown } from 'shared/queries';
import { useNonStandardAndStandardUomDropdown } from 'shared/queries/nonStandardAndStandardUomDropdownQuery';
import { CustomerPriceEditUOM } from './CustomerPriceEditUOM';
import { CustomerPrices } from './customerPrices';
import { GridEditDateCell } from './gridEditDateCell';

export const pageName = 'Customer Prices';

export function CustomerPricesResolver() {
    const { prerequisites, options } = useAnalyticsAppInfo();
    const isDemoMode = useIsDemoMode();
    useTitle(`${pageName} - ${isDemoMode ? 'PMT' : 'Nouryon'}`);
    const user = useUser();

    const {
        exchangeRates: { isLoading },
    } = useExchangeRates();

    if (isLoading || !user) {
        return <Loading />;
    }

    return (
        <PageLayout pageName={pageName}>
            <Stack gap={2}>
                <ProvideAnalyticsPrerequisites prerequisites={prerequisites} options={options}>
                    <ProvideAvailableAnalyticsReports>
                        {/* <CustomerPricesSummaryResolver> */}
                        <ProvideSelectedCustomerPrice>
                            <CustomerPrices user={user} />
                        </ProvideSelectedCustomerPrice>
                        {/* </CustomerPricesSummaryResolver> */}
                    </ProvideAvailableAnalyticsReports>
                </ProvideAnalyticsPrerequisites>
            </Stack>
        </PageLayout>
    );
}

type useCustomerPricesColumnsProps = {
    openDrawer: (selectedCustomerPrice: IViewCustomerPrices) => void;
};

export function useCustomerPricesColumns({
    openDrawer,
}: useCustomerPricesColumnsProps): RemoveableGridColDef<IViewCustomerPrices>[] {
    const permissions = useUserPermissions();

    const { exchangeRates, isForeignCurrency } = useExchangeRates();
    const { uomValueOptions } = useUomDropdown();
    const { uomValueOptions: allUomValueOptions } = useNonStandardAndStandardUomDropdown();

    const columns: RemoveableGridColDef<IViewCustomerPrices>[] = useMemo(() => {
        const documentCurrencyOptions = [
            ...(exchangeRates?.data?.map(aCurrencyCode => {
                return aCurrencyCode.fromCurrencyCode;
            }) || []),
        ];

        // type PriceTypeOption = 'Contract' | 'Formula' | 'Supply Agreement' | '';

        // const priceTypeOptions: PriceTypeOption[] = ['Contract', 'Formula', 'Supply Agreement', ''];

        return [
            {
                headerName: 'ID',
                field: 'id',
                headerAlign: 'center',
                description: 'Unique ID identifier for each row in the application',
            },
            {
                headerName: "New Record's Id",
                field: 'newRecordId',
            },
            {
                headerName: 'Business Line',
                field: 'businessLine',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
                renderCell: params => (
                    <div title={params.row.businessLine?.toUpperCase()}>{params.row.businessLine?.toUpperCase()}</div>
                ),
            },
            {
                headerName: 'Parent Customer',
                field: 'parent',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Parent Number',
                field: 'parentId',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Sold To Customer',
                field: 'soldTo',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Sold To Number',
                field: 'soldToId',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
                valueFormatter: params => (params.value?.includes('Prospective: ') ? '' : params.value),
            },
            {
                headerName: 'Ship To Customer',
                field: 'shipTo',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
                renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                        <a
                            href={'/#'}
                            onClick={event => {
                                event.preventDefault();
                                openDrawer(params.row);
                            }}
                        >
                            {params.value}
                        </a>
                    );
                },
            },
            {
                headerName: 'Ship To Number',
                field: 'shipToId',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
                valueFormatter: params => (params.value?.includes('Prospective: ') ? '' : params.value),
            },
            {
                headerName: 'Ship To Country',
                field: 'shipToCountry',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'City',
                field: 'shipToCity',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Material Number',
                field: 'materialId',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Product Line',
                field: 'productLine',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Material',
                field: 'material',
                width: 250,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Org Region',
                field: 'orgRegion',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            {
                headerName: 'Application',
                field: 'application',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height gray-header',
            },
            { headerName: 'Product Hierarchy 3', field: 'productHierarchy3', width: 200, headerAlign: 'center' },
            {
                headerName: 'Product Hierarchy 2',
                field: 'productHierarchy2',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Account Assignment Group',
                field: 'accountAssignmentGroup',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Market Segment',
                field: 'marketSegment',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Sub Market',
                field: 'applicationSubMarket',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Application Group',
                field: 'applicationGroup',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Sales Channel',
                field: 'salesChannel',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Material Type',
                field: 'materialType',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Sales Organization',
                field: 'salesOrganization',
                width: 200,
                headerAlign: 'center',
                description: 'Sales organization as recorded in the pricing condition in SAP',
            },
            {
                headerName: 'Division',
                field: 'division',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'Distribution Channel',
                field: 'distributionChannel',
                width: 200,
                headerAlign: 'center',
            },
            {
                headerName: 'TTM Quantity',
                field: 'ttmQuantityUom',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                type: 'number',
                valueFormatter: params => formatNumberNoDecimal(params.value),
                description:
                    'Sum of quantity invoiced for the Sold-To, Ship-To, Material combination over the last 12 months',
            },
            {
                headerName: 'TTM Revenue FCA',
                field: 'ttmRevenue',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                description:
                    'Sum of Net Sales minus allocated freight cost for the Sold-To, Ship-To, Material combination over the last 12 months',
            },
            {
                headerName: 'Current Price in SAP',
                field: 'currentPrice',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                type: 'number',
                renderCell: params => {
                    if (isNil(params.value)) return <NullCell {...params} />;
                    return <CustomerPricesCurrentSAPPriceStaticRenderCell {...params} />;
                },
                description: 'Price currently active in SAP (SAP transaction code VK11)',
            },
            {
                headerName: 'Current Document Currency in SAP',
                field: 'SAPDocumentCurrency',
                width: 200,
                headerAlign: 'center',
                type: 'string',
            },
            {
                headerName: 'Per Quantity in SAP',
                field: 'perQuantityInSAP',
                width: 200,
                type: 'number',
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                description: 'Per Quantity for price currently active in SAP (SAP transaction code VK11)',
            },
            {
                headerName: 'SAP Price UOM',
                field: 'currentUOMinSAP',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                description: 'UOM for price currently active in SAP (SAP transaction code VK11)',
            },
            {
                headerName: 'Price Modified Date (SAP)',
                field: 'lastPriceChangeDate',
                width: 200,
                headerAlign: 'center',
                ...SHORT_DATE_COLUMN,
                headerClassName: 'color-header-full-height green-header',
                description: 'Date of last price change in SAP (SAP transaction code VK11)',
            },
            {
                headerName: 'Current Valid From',
                field: 'currentValidFrom',
                width: 200,
                headerAlign: 'center',
                ...SHORT_DATE_COLUMN,
                headerClassName: 'color-header-full-height green-header',
                description: 'Current validity period start date as setup in SAP',
            },
            {
                headerName: 'Current Valid To',
                field: 'currentValidTo',
                width: 200,
                headerAlign: 'center',
                ...SHORT_DATE_COLUMN,
                headerClassName: 'color-header-full-height green-header',
                description: 'Current validity period end date as setup in SAP',
            },
            {
                headerName: 'Regional Target',
                field: 'regionalTargetTimesPerQuantity',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height green-header',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                description: 'Target price approved by the regional marketing team',
            },
            {
                headerName: 'Recommended Price',
                field: 'recommendedPriceTimesPerQuantity',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                description: 'Price recommended by the pricing engine',
            },
            {
                headerName: 'Direct Selling Cost',
                field: 'directSellingCost',
                width: 200,
                headerAlign: 'center',
                description: 'Direct selling cost from the most recent invoice',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
            },
            {
                headerName: 'Variable Cost',
                field: 'variableCost',
                width: 200,
                headerAlign: 'center',
                description: 'Variable cost from the most recent invoice',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
            },
            {
                headerName: 'Recommended Adjusted Margin $',
                field: 'recommendedAdjustedMargin',
                width: 200,
                headerAlign: 'center',
                description:
                    'Calculation is: ([Recommended Price]-[ most recent Direct Selling Cost]-[ most recent Variable Production Cost]) * Per Quantity. Does not take into the calculation the rebates, discounts, surcharges if any are negotiated for the customer/material',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
            },
            {
                headerName: 'Recommended Adjusted Margin %',
                field: 'recommendedAdjustedMarginPercent',
                width: 200,
                headerAlign: 'center',
                ...PERCENT_COLUMN,
                description:
                    'Calculation is: ([Recommended Price]-[ most recent Direct Selling Cost]-[ most recent Variable Production Cost])/[Revised Price]. Does not take into the calculation the rebates, discounts, surcharges if any are negotiated for the customer/material',
            },
            {
                headerName: 'Product Reach Category',
                field: 'productReachCategory',
                width: 200,
                headerAlign: 'center',
                description:
                    'Index of product popularity, defined by the number of unique customers that purchase a product compared to similar products',
            },
            {
                headerName: 'Elasticity Category',
                field: 'elasticityCategory',
                width: 200,
                headerAlign: 'center',
                description:
                    'Regression calculated at the material level to identify how volume changes in response to a change in price historically',
            },
            {
                headerName: 'Product Tier',
                field: 'productTier',
                width: 200,
                headerAlign: 'center',
                description:
                    "Revenue tier based on material's annual BL revenue, labeling largest materials Tier A and smallest Tier D",
            },
            {
                headerName: 'Ag Food Composite Index',
                field: 'agFoodCompositeIndex',
                width: 200,
                headerAlign: 'center',
                ...PERCENT_COLUMN,
                description:
                    'Composite index created by INSIGHT derived from commodity price indices. Made up of 80% Agriculture commodity price index and 20% Food and Beverage commodity price index values',
            },
            {
                headerName: 'PMI',
                field: 'pmi',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                description:
                    "Purchasing Managers' Index value, measures the direction of economic trends in the manufacturing space",
            },
            {
                headerName: 'Product Peer',
                field: 'productPeer',
                width: 200,
                headerAlign: 'center',
                description: 'Segment used in Price Corridor Engine (Marketing)',
            },
            {
                headerName: 'Customer Tier',
                field: 'customerTier',
                width: 200,
                headerAlign: 'center',
                description:
                    "Revenue tier based on customer's annual revenue, labeling largest customers Tier I and smallest Tier IV - This is not the customer segmentation as maintained in SAP",
            },
            {
                headerName: 'Purchasing Breadth Category',
                field: 'purchasingBreadthCategory',
                width: 200,
                headerAlign: 'center',
                description:
                    "An index of how broad a customer's purchasing portfolio is defined by the number of unique products that the customer purchases compared to other similar customers",
            },
            // {
            //     headerName: 'Price Type',
            //     field: 'priceTypeAttributes',
            //     type: 'singleSelect',
            //     width: 200,
            //     editable: true,
            //     headerClassName: 'color-header-full-height light-blue-header',
            //     cellClassName: 'editable-cell',
            //     valueOptions: priceTypeOptions,
            //     description: 'Indicates contract price, formula price, or supply agreement',
            //     renderEditCell: (params: GridRenderEditCellParams<number>) => {
            //         const newValue = params.value ? params.value : '';
            //         const newParams = { ...params, value: newValue };

            //         return <CustomerPriceEditPriceType {...newParams} />;
            //     },
            // },
            {
                headerName: 'Revised Price',
                field: 'revisedPrice',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'revisedPrice',
                }),
                preProcessEditCellProps: params => {
                    const shouldValidate = (() => {
                        if (params.row?.isScalePrice && !params.otherFieldsProps?.isScalePrice) return false;
                        return !params.otherFieldsProps?.isScalePrice.value;
                    })();
                    return shouldValidate ? PositiveNonZero(params) : { ...params.props, error: false };
                },
                renderCell: params => {
                    if (params.row.isScalePrice) {
                        return <NullCell {...params} />;
                    }
                    if (!params.row.isScalePrice && isNil(params.row.revisedPrice)) {
                        return <NullCell {...params} isDisplayEditable />;
                    }

                    return <CustomerPricesForeignCurrencyRenderCell {...params} />;
                },
                headerClassName: 'color-header-full-height light-blue-header',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                renderEditCell: params =>
                    params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
            },
            {
                headerName: 'Document Currency',
                field: 'documentCurrency',
                type: 'singleSelect',
                hide: false,
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                editable: true,
                valueOptions: documentCurrencyOptions,
                renderCell: params => {
                    return <DocumentCurrencyCell {...params} />;
                },
                renderEditCell: (params: GridRenderEditCellParams<string>) => {
                    if (isForeignCurrency) return <DocumentCurrencyCell {...params} />;
                    return <GridEditSingleSelectCell {...params} />;
                },
                description: 'Document currency from last invoice',
            },
            {
                headerName: 'Per Quantity',
                field: 'perQuantity',
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                cellClassName: 'editable-cell',
                valueGetter: params => {
                    return params.row.perQuantity || params.row.perQuantityInSAP;
                },
                editable: true,
                preProcessEditCellProps: params => perQuantityValidationCheck(params),
                renderEditCell: params => <ValidatedRenderEditCell {...params} />,
            },
            {
                headerName: 'UOM',
                field: 'uom',
                type: 'singleSelect',
                width: 200,
                headerAlign: 'center',
                editable: true,
                headerClassName: 'color-header-full-height light-blue-header',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                valueOptions: (params: GridValueOptionsParams) => {
                    // for grid filter only
                    if (!params.row) {
                        return allUomValueOptions;
                    }
                    return alphaNumericSort(uomValueOptions, ({ value }) => value);
                },
                description: 'Unit of Measure use for pricing',
                renderCell: params => <UnitOfMeasureCell {...params} />,
                renderEditCell: (params: GridRenderEditCellParams<string>) => {
                    if (isForeignCurrency) return <UnitOfMeasureCell {...params} />;
                    return <CustomerPriceEditUOM {...params} />;
                },
            },
            {
                headerName: '[Revised Price / Per Quantity ] - [Last Invoice Freight Cost]',
                field: 'revisedPriceWithoutFreight',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params =>
                    params.row.isScalePrice ? (
                        <NullCell {...params} />
                    ) : (
                        <CustomerPricesForeignCurrencyRenderCell {...params} />
                    ),
                headerClassName: 'color-header-full-height blue-header',
                description:
                    'Calculated as [Revised Price / Per Quantity] - [Last Invoice Freight Cost]. Used to determine need for approvals',
            },
            {
                headerName: 'Scale Pricing',
                field: 'isScalePrice',
                headerAlign: 'center',
                description: 'Enables editing scale prices',
                type: 'boolean',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
            },
            {
                headerName: 'Scale 1',
                field: 'scaleQty01',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Scale Pricing quantity 1',
                type: 'number',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                valueFormatter: params => formatNumberNoDecimal(params.value),
                renderCell: params => {
                    if (!params.row.isScalePrice) return <NullCell {...params} />;
                    return <DynamicEditableRenderCell {...params} />;
                },
            },
            {
                headerName: 'Amount 1',
                field: 'amount01',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                type: 'number',
                description: 'Price amount for scale quantity 1',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'amount01',
                }),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params =>
                    !params.row.isScalePrice ? (
                        <NullCell {...params} />
                    ) : (
                        <CustomerPricesForeignCurrencyRenderCell {...params} />
                    ),
            },
            {
                headerName: 'Scale 2',
                field: 'scaleQty02',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Scale Pricing quantity 2',
                type: 'number',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueFormatter: params => formatNumberNoDecimal(params.value),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params => {
                    if (!params.row.isScalePrice) return <NullCell {...params} />;
                    return <DynamicEditableRenderCell {...params} />;
                },
                preProcessEditCellProps: params => compareAscendingScaleValues(params, 'scaleQty01'),
            },
            {
                headerName: 'Amount 2',
                field: 'amount02',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Price amount for scale quantity 2',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'amount02',
                }),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params =>
                    !params.row.isScalePrice ? (
                        <NullCell {...params} />
                    ) : (
                        <CustomerPricesForeignCurrencyRenderCell {...params} />
                    ),
                preProcessEditCellProps: params =>
                    compareDescendingAmountValues({
                        params,
                        previousAmountFieldName: 'amount01',
                    }),
            },
            {
                headerName: 'Scale 3',
                field: 'scaleQty03',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Scale Pricing quantity 3',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueFormatter: params => formatNumberNoDecimal(params.value),
                preProcessEditCellProps: params => compareAscendingScaleValues(params, 'scaleQty02'),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params => {
                    if (!params.row.isScalePrice) return <NullCell {...params} />;
                    return <DynamicEditableRenderCell {...params} />;
                },
            },
            {
                headerName: 'Amount 3',
                field: 'amount03',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Price amount for scale quantity 3',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'amount03',
                }),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params =>
                    !params.row.isScalePrice ? (
                        <NullCell {...params} />
                    ) : (
                        <CustomerPricesForeignCurrencyRenderCell {...params} />
                    ),
                preProcessEditCellProps: params =>
                    compareDescendingAmountValues({
                        params,
                        previousAmountFieldName: 'amount02',
                    }),
            },
            {
                headerName: 'Scale 4',
                field: 'scaleQty04',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Scale Pricing quantity 4',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueFormatter: params => formatNumberNoDecimal(params.value),
                preProcessEditCellProps: params => compareAscendingScaleValues(params, 'scaleQty03'),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params => {
                    if (!params.row.isScalePrice) return <NullCell {...params} />;
                    return <DynamicEditableRenderCell {...params} />;
                },
            },
            {
                headerName: 'Amount 4',
                field: 'amount04',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Price amount for scale quantity 4',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'amount04',
                }),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params =>
                    !params.row.isScalePrice ? (
                        <NullCell {...params} />
                    ) : (
                        <CustomerPricesForeignCurrencyRenderCell {...params} />
                    ),
                preProcessEditCellProps: params =>
                    compareDescendingAmountValues({
                        params,
                        previousAmountFieldName: 'amount03',
                    }),
            },
            {
                headerName: 'Scale 5',
                field: 'scaleQty05',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Scale Pricing quantity 5',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueFormatter: params => formatNumberNoDecimal(params.value),
                preProcessEditCellProps: params => compareAscendingScaleValues(params, 'scaleQty04'),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params => {
                    if (!params.row.isScalePrice) return <NullCell {...params} />;
                    return <DynamicEditableRenderCell {...params} />;
                },
            },
            {
                headerName: 'Amount 5',
                field: 'amount05',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Price amount for scale quantity 5',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'amount05',
                }),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params =>
                    !params.row.isScalePrice ? (
                        <NullCell {...params} />
                    ) : (
                        <CustomerPricesForeignCurrencyRenderCell {...params} />
                    ),
                preProcessEditCellProps: params =>
                    compareDescendingAmountValues({
                        params,
                        previousAmountFieldName: 'amount04',
                    }),
            },
            {
                headerName: 'Scale 6',
                field: 'scaleQty06',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Scale Pricing quantity 6',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueFormatter: params => formatNumberNoDecimal(params.value),
                preProcessEditCellProps: params => compareAscendingScaleValues(params, 'scaleQty05'),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params => {
                    if (!params.row.isScalePrice) return <NullCell {...params} />;
                    return <DynamicEditableRenderCell {...params} />;
                },
            },
            {
                headerName: 'Amount 6',
                field: 'amount06',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                description: 'Price amount for scale quantity 6',
                editable: permissions.data.isRevisedCustomerPriceWriteAllowed,
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'amount06',
                }),
                renderEditCell: params =>
                    !params.row.isScalePrice ? <NullCell {...params} /> : <ValidatedRenderEditCell {...params} />,
                renderCell: params =>
                    !params.row.isScalePrice ? (
                        <NullCell {...params} />
                    ) : (
                        <CustomerPricesForeignCurrencyRenderCell {...params} />
                    ),
                preProcessEditCellProps: params =>
                    compareDescendingAmountValues({
                        params,
                        previousAmountFieldName: 'amount05',
                    }),
            },
            {
                headerName: 'Adjusted Margin $',
                field: 'adjustedMargin',
                width: 200,
                headerAlign: 'center',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                description:
                    'Calculation is: ([Revised Price]-[ most recent Direct Selling Cost]-[ most recent Variable Production Cost]). Does not take into the calculation the rebates, discounts, surcharges if any are negotiated for the customer/material',
            },
            {
                headerName: 'Adjusted Margin %',
                field: 'adjustedMarginPercent',
                width: 200,
                headerAlign: 'center',
                ...PERCENT_COLUMN,
                description:
                    'Calculation is: ([Revised Price]-[ most recent Direct Selling Cost]-[ most recent Variable Production Cost])/[Revised Price]. Does not take into the calculation the rebates, discounts, surcharges if any are negotiated for the customer/material',
            },
            // {
            //     headerName: 'Price Record Type',
            //     field: 'priceType',
            //     width: 200,
            //     headerAlign: 'center',
            //     headerClassName: 'color-header-full-height blue-header',
            // },
            {
                headerName: 'Incoterms',
                field: 'incoterms',
                width: 200,
                headerAlign: 'center',
                description: 'Incoterms from most recent invoice',
                headerClassName: 'color-header-full-height blue-header',
            },
            {
                headerName: 'Last Invoice Freight Cost',
                field: 'lastInvoiceFreightCost',
                width: 200,
                headerAlign: 'center',
                description: 'Freight cost per unit from most recent invoice',
                headerClassName: 'color-header-full-height blue-header',
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
            },
            {
                headerName: 'Payment Terms',
                field: 'paymentTerms',
                width: 200,
                headerAlign: 'center',
                description: 'Payment terms from most recent invoice',
                headerClassName: 'color-header-full-height blue-header',
            },
            {
                headerName: 'Valid From',
                field: 'validFrom',
                width: 200,
                headerAlign: 'center',
                ...SHORT_DATE_COLUMN,
                headerClassName: 'color-header-full-height blue-header',
                editable: true,
                cellClassName: 'editable-cell',
                preProcessEditCellProps: ValidFromPreProcessor,
                renderEditCell: (params: GridRenderEditCellParams) => <GridEditDateCell {...params} />,
                description: 'Validity period start date for newly set price',
            },
            {
                headerName: 'Valid To',
                field: 'validTo',
                width: 200,
                headerAlign: 'center',
                ...SHORT_DATE_COLUMN,
                headerClassName: 'color-header-full-height blue-header',
                editable: true,
                cellClassName: 'editable-cell',
                preProcessEditCellProps: ValidToPreProcessor,
                renderEditCell: (params: GridRenderEditCellParams) => <GridEditDateCell {...params} disablePast />,
                description: 'Validity period end date for newly set price',
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height blue-header',
                description: 'Shows when price requires review, requires approval, or is approved',
            },
            {
                headerName: 'Approver',
                field: 'approver',
                width: 200,
                headerAlign: 'center',
                headerClassName: 'color-header-full-height blue-header',
                description: 'Role of price approver',
            },
            {
                headerName: 'Last Edited By',
                field: 'modifiedBy',
                width: 200,
                headerAlign: 'center',
                valueFormatter: modifiedByFormatter,
                headerClassName: 'color-header-full-height blue-header',
                description: 'Last person to edit price in application',
            },
            {
                headerName: 'Last Edited Date',
                field: 'effectiveStart',
                width: 200,
                headerAlign: 'center',
                ...LONG_DATE_COLUMN,
                headerClassName: 'color-header-full-height blue-header',
                description: 'Last time the price was edited in application',
            },
            {
                headerName: 'Submit Price',
                field: 'action-submit',
                type: 'actions',
                hide: false,
                filterable: false,
                pinnable: false,
                width: 125,
                headerAlign: 'center',
                getActions: (params: GridRowParams<IViewCustomerPrices>): JSX.Element[] => [
                    <CustomerPricesSubmitAction key={`submit-button-${params.row.id}`} params={params} />,
                ],
            },
            {
                headerName: 'Approve',
                field: 'approval-actions',
                type: 'actions',
                hide: false,
                filterable: false,
                pinnable: false,
                width: 125,
                headerAlign: 'center',
                getActions: (params: GridRowParams<IViewCustomerPrices>): JSX.Element[] =>
                    CustomerProductApprovalActions({ params }),
                customFieldIsReadAllowed: permissions.userApproverTier >= 1,
            },
            {
                headerName: 'Exceptions',
                field: 'exception-actions',
                type: 'actions',
                hide: false,
                filterable: false,
                pinnable: false,
                width: 125,
                headerAlign: 'center',
                getActions: (params: GridRowParams<IViewCustomerPrices>): JSX.Element[] =>
                    CustomerPricesExceptionsActions({ params }),
                customFieldIsReadAllowed: permissions.featureFlags.isViewCpExceptionsAllowed,
            },
            {
                headerName: 'Exception Status',
                field: 'exceptionStatus',
                width: 200,
                headerAlign: 'center',
                /**For filter options purposes */
                type: 'singleSelect',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                valueOptions: [...EXCEPTION_STATUS_OPTIONS],
            },
            {
                headerName: 'Customer Service Rep',
                field: 'customerServiceRep',
                headerAlign: 'center',
            },
            {
                headerName: 'Account Manager',
                description: 'Account Manager assigned to the customer item combination',
                field: 'rlsName',
                headerAlign: 'center',
            },
            {
                headerName: 'Data Source',
                field: 'dataSource',
                headerAlign: 'center',
                description: 'Indicates rows from pricing tool vs rows added by users',
            },
        ];
    }, [permissions, exchangeRates, openDrawer, isForeignCurrency, uomValueOptions, allUomValueOptions]);

    const filteredColumns = columns.filter(column => column.customFieldIsReadAllowed !== false);

    return filteredColumns;
}
