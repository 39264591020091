import { useEffect } from 'react';
import { useService, CLIENT_ID } from '@insight2profit/drive-app';

export const useTrackPageView = (action: string, displayName: string, email: string, id: string) => {
    const { activityTrackingService } = useService();
    const { appConfig } = useService();

    useEffect(() => {
        activityTrackingService.trackStandardUserActivityEvent({
            action: action,
            user: {
                displayName,
                email,
                id,
            },
            correlationId: '',
            eventType: 'PageView',
            applicationId: appConfig.appId,
            clientId: CLIENT_ID,
        });
    }, [action, displayName, email, id, appConfig.appId, activityTrackingService]);
};
